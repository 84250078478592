import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CountToModule } from 'angular-count-to';
import { NgxMasonryModule } from 'ngx-masonry';
import { ScrollspyDirective } from './shared/scrollspy.directive'
import { FeatherModule } from 'angular-feather';

import { allIcons } from 'angular-feather/icons';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { SwitcherComponent } from './shared/switcher/switcher.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ServicesComponent } from './sections/services/services.component';
import { ContactComponent } from './sections/contact/contact.component';
import { CariersComponent } from './sections/cariers/cariers.component';
import { TechonologiesComponent } from './sections/techonologies/techonologies.component';
import { AboutComponent } from './sections/about/about.component';
import { FeaturesComponent } from './sections/features/features.component';
import { WorkprocessComponent } from './sections/workprocess/workprocess.component';
import { SoftwareSolutionsComponent } from './sections/software-solutions/software-solutions.component';
import { GetinTouchComponent } from './sections/getin-touch/getin-touch.component';
import { HomeComponent } from './sections/home/home.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { JobPageComponent } from './pages/job-page/job-page.component';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { JobsComponent } from './sections/jobs/jobs.component';
import { VisionMissionComponent } from './sections/vision-mission/vision-mission.component';
import { MobileAppComponent } from './pages/mobile-app/mobile-app.component';
import { SoftwareDevelopComponent } from './pages/software-develop/software-develop.component';
import { UiuxDesignComponent } from './pages/uiux-design/uiux-design.component';
import { PageJobDetailComponent } from './pages/page-job-detail/page-job-detail.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { IndexComponent } from './core/index/index.component';
import { MasterPageComponent } from './core/master-page/master-page.component';
import { WhatWeDoComponent } from './sections/what-we-do/what-we-do.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { PrivacyPolicyMyAndroidComponent } from './pages/privacy-policy-my-android/privacy-policy-my-android.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

export function HttpLoaderFactory(http: HttpClient):TranslateHttpLoader {  
  return new TranslateHttpLoader(http,'./assets/i18n/','.json');  
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    IndexComponent,
    PageJobDetailComponent,
    SwitcherComponent,
    ScrollspyDirective,
    ServicesComponent,
    ContactComponent,
    CariersComponent,
    TechonologiesComponent,
    AboutComponent,
    FeaturesComponent,
    WorkprocessComponent,
    SoftwareSolutionsComponent,
    GetinTouchComponent,
    HomeComponent,
    ContactPageComponent,
    JobPageComponent,
    AboutPageComponent,
    JobsComponent,
    VisionMissionComponent,
    MobileAppComponent,
    SoftwareDevelopComponent,
    UiuxDesignComponent,
    MasterPageComponent,
    WhatWeDoComponent,
    PrivacyPolicyComponent,
    PrivacyPolicyMyAndroidComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    CarouselModule,
    FeatherModule.pick(allIcons),
    ScrollToModule.forRoot(),
    RouterModule.forRoot([], { relativeLinkResolution: 'legacy' }),
    NgxYoutubePlayerModule,
    NgbModule,
    NgbNavModule,
    FormsModule,
    SwiperModule,
    NgxTypedJsModule,
    FlatpickrModule.forRoot(),
    CountToModule,
    HttpClientModule,
    NgxMasonryModule,
    TranslateModule.forRoot({  
      loader: {  
         provide: TranslateLoader,  
         useFactory: HttpLoaderFactory,  
         deps: [HttpClient]  
         }  
      })
  ],
  exports: [
    FeatherModule,
    ScrollspyDirective
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}