<div class="container" id="home">
    <div class="row align-items-center">
        <div class="col-lg-7 col-md-7">
            <div class="title-heading mt-4">
                <h1 class="heading mb-3"><span class="text-primary">
                    <ngx-typed-js *ngIf="isLoad" [strings]="titles" [shuffle]="true" [typeSpeed]="80" [loop]="true">
                        <span class="element typing"></span>
                    </ngx-typed-js>
                    </span> {{'home.subtitle' | translate}} </h1>
                <p class="para-desc text-muted">{{ 'home.description' | translate }}</p>
                <div class="mt-4">
                    <a routerLink="/page-contact" class="btn btn-primary mt-2 mr-2"><i class="mdi mdi-email"></i>  {{ 'home.button' | translate }}</a>
                </div>
            </div>
        </div>
        <!--end col-->
        <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <img src="assets/images/artemitsoft/software_development.svg" class="img-fluid mover mx-auto d-block " alt="">
        </div>
        <!--end col-->
    </div>
    <!--end row-->
</div>
<!--end container-->