import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-getin-touch-banner',
  templateUrl: './getin-touch.component.html',
  styleUrls: ['./getin-touch.component.css']
})
export class GetinTouchComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
