<section class="section overflow-hidden">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <span class="badge badge-pill badge-primary mb-2">{{ 'workFeatures.title' | translate }}</span>
                    <h4 class="title mb-4">{{ 'workFeatures.subtitle' | translate }}</h4>
                    <p class="text-muted para-desc mx-auto mb-0">{{ 'workFeatures.description' | translate }}</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-12 mt-4 pt-2">
                <div class="card text-center rounded border-0">
                    <div class="card-body">
                        <div class="p-3 bg-light rounded shadow d-inline-block">
                            <img src="assets/images/icon/art-and-design.svg" class="avatar avatar-small" alt="">
                        </div>
                        <div class="mt-4">
                            <h5><a href="javascript:void(0)" class="text-dark">{{ 'workFeatures.discussion.title' | translate }}</a></h5>
                            <p class="text-muted mt-3 mb-0">{{ 'workFeatures.discussion.description' | translate }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-4 col-12 mt-4 pt-2">
                <div class="card text-center rounded border-0">
                    <div class="card-body">
                        <div class="p-3 bg-light rounded shadow d-inline-block">
                            <img src="assets/images/icon/smartphone.svg" class="avatar avatar-small" alt="">
                        </div>
                        <div class="mt-4">
                            <h5><a href="javascript:void(0)" class="text-dark">{{ 'workFeatures.strategyPlanning.title' | translate }}</a></h5>
                            <p class="text-muted mt-3 mb-0">{{ 'workFeatures.strategyPlanning.description' | translate }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-4 col-12 mt-4 pt-2">
                <div class="card text-center rounded border-0">
                    <div class="card-body">
                        <div class="p-3 bg-light rounded shadow d-inline-block">
                            <img src="assets/images/icon/clock.svg" class="avatar avatar-small" alt="">
                        </div>
                        <div class="mt-4">
                            <h5><a href="javascript:void(0)" class="text-dark">{{ 'workFeatures.reporting.title' | translate }}</a></h5>
                            <p class="text-muted mt-3 mb-0">{{ 'workFeatures.reporting.description' | translate }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
