<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level">
                    <img [src]="selectedJob.icon" class="avatar avatar-small" alt="">
                    <h4 class="title mt-4 mb-3"> {{'description.item1'  | translate  }}</h4>
                    <p class="para-desc mx-auto text-muted">{{'description.item2' | translate }}</p>
                    <ul class="list-unstyled">
                        <li class="list-inline-item text-primary mr-3"><i class="mdi mdi-map-marker text-warning mr-2"></i>{{selectedJob.location}}
                        </li>
                        <!-- <li class="list-inline-item text-primary"><i class="mdi mdi-office-building text-warning mr-2"></i>Gradle
                        </li> -->
                    </ul>
                    <div class="page-next">
                        <nav aria-label="breadcrumb" class="d-inline-block">
                            <ul class="breadcrumb bg-white rounded shadow mb-0">
                                <li class="breadcrumb-item"><a routerLink="/index">ArtemitSoft</a></li>
                                <li class="breadcrumb-item"><a href="/page-job">Careers</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Job Details</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
     </svg>
    </div>
</div>
<!--Shape End-->

<!-- Job Detail Start -->
<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-5 col-12">
                <div class="card sidebar sticky-bar rounded shadow border-0">
                    <div class="card-body widget border-bottom">
                        <h5 class="mb-0">Job Information</h5>
                    </div>

                    <div class="card-body">
                        <div class="media widget align-items-center">
                            <i-feather name="user-check" class="fea icon-ex-md mr-3"></i-feather>
                            <div class="media-body">
                                <h4 class="widget-title mb-0">Employee Type:</h4>
                                <p class="text-primary mb-0">{{selectedJob.employeeType}}</p>
                            </div>
                        </div>

                        <div class="media widget align-items-center mt-3">
                            <i-feather name="map-pin" class="fea icon-ex-md mr-3"></i-feather>
                            <div class="media-body">
                                <h4 class="widget-title mb-0">Location:</h4>
                                <p class="text-primary mb-0">{{selectedJob.location}}</p>
                            </div>
                        </div>

                        <div class="media widget align-items-center mt-3">
                            <i-feather name="monitor" class="fea icon-ex-md mr-3"></i-feather>
                            <div class="media-body">
                                <h4 class="widget-title mb-0">Job Type:</h4>
                                <p class="text-primary mb-0">{{selectedJob.jobType}}</p>
                            </div>
                        </div>

                        <div class="media widget align-items-center mt-3">
                            <i-feather name="briefcase" class="fea icon-ex-md mr-3"></i-feather>
                            <div class="media-body">
                                <h4 class="widget-title mb-0">Experience:</h4>
                                <p class="text-primary mb-0">+{{selectedJob.experience}} Year</p>
                            </div>
                        </div>

                        <!-- <div class="media widget align-items-center mt-3">
                            <i-feather name="book" class="fea icon-ex-md mr-3"></i-feather>
                            <div class="media-body">
                                <h4 class="widget-title mb-0">Qualifications:</h4>
                                <p class="text-primary mb-0">MSCIT</p>
                            </div>
                        </div>

                        <div class="media widget align-items-center mt-3">
                            <i-feather name="dollar-sign" class="fea icon-ex-md mr-3"></i-feather>
                            <div class="media-body">
                                <h4 class="widget-title mb-0">Salary:</h4>
                                <p class="text-primary mb-0">+50k to 70k</p>
                            </div>
                        </div>

                        <div class="media widget align-items-center mt-3">
                            <i-feather name="clock" class="fea icon-ex-md mr-3"></i-feather>
                            <div class="media-body">
                                <h4 class="widget-title mb-0">Date posted:</h4>
                                <p class="text-primary mb-0 mb-0">5th Sep, 2019</p>
                            </div>
                        </div> -->

                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-8 col-md-7 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="ml-lg-4">
                    <h5>Job Description: </h5>
                    <div *ngFor="let desc of selectedJob.description">
                        <p class="text-muted">{{desc}}</p>
                    </div>


                    <h5 class="mt-4">Responsibilities and Duties: </h5>
                    <ul class="list-unstyled" *ngFor="let resp of selectedJob.responsibilities">
                        <li class="text-muted">
                            <i-feather name="arrow-right" class="fea icon-sm text-success mr-2"></i-feather>
                            {{resp}}
                        </li>
                    </ul>

                    <h5 class="mt-4">Required Experience, Skills and Qualifications: </h5>
                    <ul class="list-unstyled" *ngFor="let skill of selectedJob.skillsAndQualifications">
                        <li class="text-muted">
                            <i-feather name="arrow-right" class="fea icon-sm text-success mr-2"></i-feather>
                            {{skill}}
                        </li>
                    </ul>

                    <div class="mt-4">
                        <a routerLink="/page-job-apply" class="btn btn-outline-primary">Apply Now <i class="mdi mdi-send"></i></a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Job Detail End -->


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->