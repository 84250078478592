<section class="bg-half bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level">
                    <h4 class="title"> {{ 'contact.title' | translate }} </h4>
                    <div class="page-next">
                        <nav aria-label="breadcrumb" class="d-inline-block">
                            <ul class="breadcrumb bg-white rounded shadow mb-0">
                                <li class="breadcrumb-item"><a routerLink="/">ArtemitSoft</a></li>
                                <li class="breadcrumb-item active" aria-current="page">{{ 'contact.title' | translate }}</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
</div>
<section class="section">
    <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4 text-primary">{{ 'contact.idea_title' | translate }}</h4>
            <p class="text-muted para-desc mx-auto mb-0"> {{ 'contact.idea_description' | translate }}.</p>
        </div>
    </div>
</section>
<section id="contact">
    <app-contact-banner></app-contact-banner>
</section>
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>