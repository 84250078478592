<!-- Hero Start -->
<section class="bg-half-170 d-table w-100 overflow-hidden" id="home">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-7">
                <div class="title-heading mt-4">
                    <h1 class="heading mb-3">{{ 'heroo.title' | translate }}</h1>
                    <p class="para-desc text-muted">{{ 'heroo.description' | translate }}</p>
                    <div class="watch-video mt-4 pt-2">
                        <a href="/page-contact" class="btn btn-primary mb-2 mr-2">{{ 'heroo.get_in_touch' | translate }}</a>
                        <ng-template #content let-modal>
                            <div class="modal-header">
                                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <youtube-player [videoId]="'jNTZpfXYJa4'" height="450" width="780"></youtube-player>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="classic-app-image position-relative">
                    <div class="position-relative">
                        <img src="assets/images/artemitsoft/user_flow.svg" class="img-fluid mover mx-auto d-block" alt="">
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!-- Hero End -->

<section>
    <app-what-we-do-banner></app-what-we-do-banner>
</section>

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
