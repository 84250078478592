<section id="workprocess" class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h6 class="text-primary">{{ 'workProccess.title' | translate }}</h6>
                    <h4 class="title mb-4">{{ 'workProccess.subtitle' | translate }}</h4>
                    <p class="text-muted para-desc mx-auto mb-0">{{ 'workProccess.description' | translate }}</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
            <div class="col-md-4 mt-4 pt-2">
                <div class="card features work-process bg-transparent process-arrow border-0 text-center">
                    <div class="icons rounded h1 text-center text-primary px-3">
                        <i class="uil uil-presentation-edit"></i>
                    </div>

                    <div class="card-body">
                        <h4 class="title text-dark">{{ 'workProccess.discussion.title' | translate }}</h4>
                        <p class="text-muted mb-0">{{ 'workProccess.discussion.description' | translate }}</p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
                <div class="card features work-process bg-transparent process-arrow border-0 text-center">
                    <div class="icons rounded h1 text-center text-primary px-3">
                        <i class="uil uil-airplay"></i>
                    </div>

                    <div class="card-body">
                        <h4 class="title text-dark">{{ 'workProccess.strategyPlanning.title' | translate }}</h4>
                        <p class="text-muted mb-0">{{ 'workProccess.strategyPlanning.description' | translate }}</p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
                <div class="card features work-process bg-transparent d-none-arrow border-0 text-center">
                    <div class="icons rounded h1 text-center text-primary px-3">
                        <i class="uil uil-image-check"></i>
                    </div>

                    <div class="card-body">
                        <h4 class="title text-dark">{{ 'workProccess.reporting.title' | translate }}</h4>
                        <p class="text-muted mb-0">{{ 'workProccess.reporting.description' | translate }}</p>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
</section>
