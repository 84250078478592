import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-techonologies-banner',
  templateUrl: './techonologies.component.html',
  styleUrls: ['./techonologies.component.css']
})
export class TechonologiesComponent implements OnInit {

  images:any[]=[]
  constructor() { }
  ngOnInit(): void {
    for (let index = 1; index < 12; index++) {
      this.images.push({
        item:index
      })
    }
  }

}
