<div class="col-12 text-center">
    <div class="section-title mb-4 pb-2">
        <h4 class="title mb-4">{{ 'join_team.title' | translate }}</h4>
        <!-- <p class="text-muted para-desc mx-auto mb-0">Start working with <span class="text-primary font-weight-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p> -->
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col-lg-6 mt-4 pt-2" *ngFor="let item of jobs">
            <a href="page-job-detail/{{item.id}}" class="text-primary">
                <div class="key-feature d-flex align-items-center p-3 bg-white rounded shadow">
                    <div class="icon text-center rounded-circle mr-3">
                        <img [src]="item.icon" class="avatar avatar-ex-sm" alt="">
                    </div>
                    <div class="content">
                        <h4 class="title mb-0">{{item.title}}</h4>
                        <p class="text-muted mb-0">{{item.employeeType}}</p>
                    </div>
                </div>
            </a>
        </div>

        <!-- <div class="col-lg-6 mt-4 pt-2">
            <a href="page-job-detail" class="text-primary">
                <div class="key-feature d-flex align-items-center p-3 bg-white rounded shadow">
                    <div class="icon text-center rounded-circle mr-3">
                        <i-feather name="monitor" class="fea icon-ex-md text-primary"></i-feather>
                    </div>
                    <div class="content">
                        <h4 class="title mb-0">Junior Software Developer</h4>
                        <p class="text-muted mb-0">Part-time * Remote</p>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-lg-6 mt-4 pt-2">
            <a href="page-job-detail" class="text-primary">
                <div class="key-feature d-flex align-items-center p-3 bg-white rounded shadow">
                    <div class="icon text-center rounded-circle mr-3">
                        <i-feather name="airplay" class="fea icon-ex-md text-primary"></i-feather>
                    </div>
                    <div class="content">
                        <h4 class="title mb-0">Android Developer</h4>
                        <p class="text-muted mb-0">Part-time * Remote</p>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-lg-6 mt-4 pt-2">
            <a href="page-job-detail" class="text-primary">
                <div class="key-feature d-flex align-items-center p-3 bg-white rounded shadow">
                    <div class="icon text-center rounded-circle mr-3">
                        <i-feather name="cpu" class="fea icon-ex-md text-primary"></i-feather>
                    </div>
                    <div class="content">
                        <h4 class="title mb-0">Ios Developer</h4>
                        <p class="text-muted mb-0">Bilişim Vadisi</p>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-lg-6 mt-4 pt-2">
            <a href="page-job-detail" class="text-primary">
                <div class="key-feature d-flex align-items-center p-3 bg-white rounded shadow">
                    <div class="icon text-center rounded-circle mr-3">
                        <i-feather name="hexagon" class="fea icon-ex-md text-primary"></i-feather>
                    </div>
                    <div class="content">
                        <h4 class="title mb-0">React Netive Developer</h4>
                        <p class="text-muted mb-0">Part-time * Remote</p>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-lg-6 mt-4 pt-2">
            <a href="page-job-detail" class="text-primary">
                <div class="key-feature d-flex align-items-center p-3 bg-white rounded shadow">
                    <div class="icon text-center rounded-circle mr-3">
                        <i-feather name="figma" class="fea icon-ex-md text-primary"></i-feather>
                    </div>
                    <div class="content">
                        <h4 class="title mb-0">UI/UX Designer</h4>
                        <p class="text-muted mb-0">Part-time * Remote</p>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-lg-6 mt-4 pt-2">
            <a href="page-job-detail" class="text-primary">
                <div class="key-feature d-flex align-items-center p-3 bg-white rounded shadow">
                    <div class="icon text-center rounded-circle mr-3">
                        <i-feather name="crop" class="fea icon-ex-md text-primary"></i-feather>
                    </div>
                    <div class="content">
                        <h4 class="title mb-0">Flutter Developer</h4>
                        <p class="text-muted mb-0">Bilişim Vadisi</p>
                    </div>
                </div>
            </a>
        </div> -->
    </div>
</div>