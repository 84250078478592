import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-banner',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  startYear: number = 2013;
  currentYear: number = 0;
  yearsCount:number = 1970; 
  
  constructor() { }
  
  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.yearsCount = this.currentYear - this.startYear ; 
  }

}
