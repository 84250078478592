<!-- Navbar STart -->
<header id="topnav" class="defaultscroll sticky" (window:scroll)="windowScroll()" [class]="navClass">
    <div class="container">
        <!-- Logo container-->
        <div>
            <a class="logo" routerLink="/index" [ngxScrollTo]="'#home'" *ngIf="navClass !== 'nav-light'">
                <img src="assets/artemitlogo.svg" class="l-dark" height="40" alt="">
                <img src="assets/artemitlogo.svg" class="l-light" height="40" alt="">
            </a>
            <a class="logo" routerLink="/index" [ngxScrollTo]="'#home'" *ngIf="navClass === 'nav-light'">
                <img src="assets/artemitlogo.svg" class="l-dark" height="40" alt="">
                <img src="assets/artemitlogo.svg" class="l-light" height="40" alt="">
            </a>
        </div>
        <div class="buy-button">
            <!-- <ng-template [ngIf]="navClass === 'nav-light'">
                <a (click)="switchLanguage('tr')" [ngxScrollTo]="'#home'" class="btn btn-primary login-btn-primary">Get in Touch</a>
                <a (click)="switchLanguage('tr')" [ngxScrollTo]="'#home'" class="btn btn-light login-btn-light">Get in Touch</a>
            </ng-template> -->
            <ng-template [ngIf]="navClass !== 'nav-light'">
                <a *ngIf="flag == 'tr'" (click)="switchLanguage('tr');flag='en'">
                    <img src="assets/images/lang-tr.png" class="l-dark" height="20" alt="">
                </a>
                <a *ngIf="flag == 'en'" (click)="switchLanguage('en');flag='tr'">
                    <img src="assets/images/lang-en.png" class="l-dark" height="20" alt="">
                </a>
            </ng-template>

        </div>

        <!--end login button-->
        <!-- End Logo container-->
        <div class="menu-extras">
            <div class="menu-item">
                <!-- Mobile menu toggle-->
                <a class="navbar-toggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">
                    <div class="lines">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </a>
                <!-- End mobile menu toggle-->
            </div>
        </div>

        <div id="navigation">
            <!-- Navigation Menu-->
            <ul class="navigation-menu" [class]="navClass">
                <li><a routerLink="/page-about" [ngxScrollTo]="'#home'" class="nav-link-ref">{{'header.about' | translate }}</a></li>
                <li class="has-submenu">
                    <a href="javascript:void(0)" (click)="onMenuClick($event)">{{'header.projects.title' | translate }}</a><span class="menu-arrow"></span>
                    <ul class="submenu megamenu">
                        <li>
                            <ul>
                                <li><a class="nav-link-ref" routerLink="/page-mobile" [ngxScrollTo]="'#home'">{{'header.projects.mobile' | translate }}</a></li>
                                <li><a class="nav-link-ref" routerLink="/page-software">{{'header.projects.software' | translate }}</a></li>
                                <li><a class="nav-link-ref" routerLink="/page-uiux">{{'header.projects.uiux' | translate }}</a></li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <!-- <li><a routerLink="/" class="nav-link-ref">Services</a></li> -->
                <li><a routerLink="/page-job" [ngxScrollTo]="'#home'" class="nav-link-ref">{{'header.careers' | translate }}</a></li>
                <li><a routerLink="/" [ngxScrollTo]="'#techonologies-banner'" class="nav-link-ref">{{'header.techonology' | translate}}</a></li>
                <li><a routerLink="/page-contact" [ngxScrollTo]="'#home'" class="nav-link-ref">{{ 'header.contact' | translate }}</a></li>
            </ul>
            <!--end navigation menu-->
            <!--end login button-->
            <!--end login button-->
        </div>
        <!--end navigation-->
    </div>
    <!--end container-->
</header>
<!--end header-->
<!-- Navbar End -->