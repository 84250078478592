<!-- Hero Start -->
<section class="bg-half d-table w-100" id="home" style="background: url('assets/images/company/aboutus.jpg');">
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level title-heading">
                    <h1 class="text-white title-dark">{{ 'hero.title' | translate }}</h1>
                    <p class="text-white-50 para-desc mb-0 mx-auto">{{ 'hero.description' | translate }}</p>
                    <div class="page-next">
                        <nav aria-label="breadcrumb" class="d-inline-block">
                            <ul class="breadcrumb bg-white rounded shadow mb-0">
                                <li class="breadcrumb-item"><a routerLink="/">{{ 'hero.breadcrumb.home' | translate }}</a></li>
                                <li class="breadcrumb-item active" aria-current="page">{{ 'hero.breadcrumb.current' | translate }}</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->

<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
</div>
<!-- Hero End -->

<section id="about-banner" class="section">
    <app-about-banner></app-about-banner>
</section>

<section id="vision-mission-banner" class="section">
    <app-vision-mission-banner></app-vision-mission-banner>
</section>

<section id="workprocess-banner" class="section bg-light">
    <app-workprocess-banner></app-workprocess-banner>
</section>

<section id="features-banner" class="section">
    <app-jobs-banner></app-jobs-banner>
</section>

<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
    {{ 'buttons.backToTop' | translate }}
</a>