<!-- Hero Start -->
<section class="bg-half-170 d-table w-100 overflow-hidden" id="home">
    <div class="container">

        <h3 class="title"><b>Privacy Policy for 'My Android'
            </b></h3>

        <p> Your privacy is important to us. This privacy policy informs you about why we collect data from our users. This privacy policy is part of our terms of service. It applies to application installation, application usage or any information collected
            on our site. Please contact us at <a href="info@artemitsoft.com">info@artemitsoft.com</a> with any questions or regarding our policies.</p>

        <p>
            ArtemitSoft (“ArtemitSoft,” “we,” “our,” and/or “us”) values the privacy of individuals who use our application, websites, and related services. This Privacy Policy explains how we collect, use, share and protect information that is collected through
            our mobile app “My Android”, as well as your choices regarding the collection and use of information.
        </p>
        <p>
            <strong>
                Information We Collect When You Use “My Android” App
            </strong>
        </p>
        <p>
            <strong>
                Call Information
            </strong> – We collect the information about outgoing, incoming and missed calls for today, yesterday and last one week to present the Call History in a meaningful manner to the user.
        </p>
        <p>
            <strong>
                Access to Contacts
            </strong> - This is required to show the contact photo in Call History to you.
        </p>
        <p>
            <strong>
                Camera Information
            </strong> – We collect this information to calculate the Mega Pixel of Front and Back Camera and show it to the user.
        </p>
        <p>
            <strong>
                Storage Information
            </strong> – We use this information to calculate how many photos the user can take with the front and rear cameras and present them to the user.
            <p>
                <strong>
                Device Information –
            </strong> We retrieve information from the user's device, including Android version name and number, device manufacturer and model number, screen size and resolution (in pixels and inches), screen density, and device RAM. We
                also collect the list of applications installed on the user's phone.
            </p>
            <p>
                <strong>
                Memory Card Information
            </strong> – We collect the phone and SD card memory space of the device and show the free/available memory space to the user.
            </p>
            <p>
                <strong>
                Internet Information
            </strong> – We also show user which internet connection (2G, 3G, 4G or Wi-Fi) they are connected to.
                <br>
            </p>
            <p>
                <strong>
                Communications
            </strong> - If you contact us directly, we may receive additional information about you. For example, when you contact our Customer Support Team or write a review we will receive your name, country, email address or may be
                phone number, the contents of a message that you may send to us, and other information you choose to provide.
            </p>

            <p>
                <strong> Device Comparison Feature </strong> - We retrieve information from the user's device, including Android version name and number, device manufacturer and model number, screen size and resolution (in pixels and inches), screen density,
                and device RAM. We also collect the list of applications installed on the user's phone.
            </p>

            <ol>
                <li>
                    <strong> Information We Collect</strong> - When the comparison feature is used, users are provided with access to information including phone features to compare phone models. This information is transmitted to the API for comparison
                    by sending the model number of the user's phone upon request.
                </li>
                <li>
                    <strong> End-to-End Encryption</strong> -To protect users' privacy, this information is end-to-end encrypted. The information users provide when comparing phone models is transmitted securely and protected against access by third parties.
                </li>
                <li>
                    <strong> Data Security </strong> - ArtemisSoft employs appropriate physical and technological security measures to protect users' personal information, including the data provided during phone model comparisons.
                </li>
                <li>
                    <strong> Sharing of Other Information </strong> - Information provided while using the comparison feature is used solely for comparison purposes at the user's request. It is not shared or sold to third parties without the user's consent.
                </li>
            </ol>

            <p>
                The above information about you is only visible to you and strictly not shared with anybody else.
            </p>
            <p>
                We will not transfer your personal information to third parties without your consent, except under the limited conditions described under the section entitled "Other Sharing” below.
            </p>
            <p>
                <strong> Other Sharing </strong>
                <br>
            </p>
            <p>
                We may share your information with third parties in the following cases:
            </p>
            <ol>
                <li>
                    With law enforcement officials, government authorities, or third parties if we think doing so is necessary to protect the rights, property, or safety of ArtemitSoft or the public.
                </li>
                <li>
                    In the event of a merger, consolidation or reorganization involving My Android, acquisition of ArtemitSoft by another company, a sale of all or a portion of ArtemitSoft's assets, or other similar transaction, your personal information will, in most instances,
                    be transferred to the control of the third party that is part of that transaction.
                </li>
                <li>
                    Whenever you consent to the sharing.
                </li>
            </ol>
            <p>
                <strong> Links  </strong>
                <br> ‘My Android’ application may contain links to other websites / applications. Please be aware that ArtemitSoft is not responsible for the privacy practices of these sites. This privacy statement applies solely to information collected
                by ArtemitSoft for ‘My Android’ application. We encourage our Users to read the privacy statements of each website they visit when leaving ‘My Android’ application.
            </p>
            <p>
                <strong>
                Data Security
            </strong>
                <br> ArtemitSoft and its companies are committed to protecting your personal information and do so with certain physical and technological procedures. However, we cannot ensure the security of the information as you transmit it to us,
                and so we urge you to take every precaution to protect your personal data when you are on the Internet. This includes using a secure browser where applicable.
            </p>
            <p>
                <strong> Changes to this Privacy Policy </strong>
                <br>
            </p>
            <p>
                This Privacy Policy may be changed from time to time. If there are significant changes in the way we use your personal information, we will notify you by a notice on this site or by email or regular mail. </p>
            <p>
                This Privacy Policy was last updated on Jun 01, 2023.
            </p>


    </div>
</section>