<div class="container">
    <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
            <div class="card features fea-primary rounded p-4 bg-light position-relative overflow-hidden border-0">
                <span class="h1 icon2 text-primary">
                    <i class="uil uil-briefcase"></i>
                </span>
                <div class="card-body p-0 content">
                    <h5>{{ 'mission.title' | translate }}</h5>
                    <p class="para text-muted mb-4" [innerHTML]=" 'mission.description1' | translate"></p>
                    <p class="para text-muted mb-0" [innerHTML]=" 'mission.description2' | translate"></p>
                </div>
            </div>
        </div>
        <!--end col-->

        <div class="col-lg-6 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div class="card features fea-primary rounded p-4 bg-light position-relative overflow-hidden border-0">
                <span class="h1 icon2 text-primary">
                    <i class="uil uil-rocket"></i>
                </span>
                <div class="card-body p-0 content">
                    <h5>{{ 'vision.title' | translate }}</h5>
                    <p class="para text-muted mb-0" [innerHTML]=" 'vision.description' | translate"></p>
                </div>
            </div>
        </div>
    </div>
    <!--end row-->
</div>
