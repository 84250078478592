<section class="bg-half-170  d-table w-100" id="home">
    <app-home-banner></app-home-banner>
</section>
<section id="software-solutions" class="section bg-light">
    <app-software-solutions-banner></app-software-solutions-banner>
</section>
<section id="workprocess-banner" class="section">
    <app-workprocess-banner></app-workprocess-banner>
</section>
<section id="features-banner" class="section">
    <app-features-banner></app-features-banner>
</section>

<!-- <div class="position-relative">
    <div class="shape overflow-hidden text-light">
        <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
     </svg>
    </div>
</div> -->

<section class="section bg-light">
    <app-getin-touch-banner></app-getin-touch-banner>
</section>

<section id="techonologies-banner" class="section">
    <app-techonologies-banner></app-techonologies-banner>
</section>

<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>