import { NgModule } from '@angular/core';
import { SwitcherComponent } from './shared/switcher/switcher.component';
import { JobPageComponent } from './pages/job-page/job-page.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { MobileAppComponent } from './pages/mobile-app/mobile-app.component';
import { UiuxDesignComponent } from './pages/uiux-design/uiux-design.component';
import { SoftwareDevelopComponent } from './pages/software-develop/software-develop.component';
import { PageJobDetailComponent } from './pages/page-job-detail/page-job-detail.component';
import { IndexComponent } from './core/index/index.component';
import { Routes, RouterModule } from '@angular/router';
import { MasterPageComponent } from './core/master-page/master-page.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { PrivacyPolicyMyAndroidComponent } from './pages/privacy-policy-my-android/privacy-policy-my-android.component';

const routes: Routes = [
  {
    path: '',
    component: MasterPageComponent,
    children: [
      { path: '', component: IndexComponent },
      {path:'page-job',component:JobPageComponent},
      {path:'page-contact',component:ContactPageComponent},
      {path:'privacy-policy',component:PrivacyPolicyComponent},
      {path:'privacy-policy-myandroid',component:PrivacyPolicyMyAndroidComponent},
      {path:'page-about',component:AboutPageComponent},
      {path:'page-mobile',component:MobileAppComponent},
      {path:'page-uiux',component:UiuxDesignComponent},
      {path:'page-software',component:SoftwareDevelopComponent},
      {path:'page-job-detail/:id', component: PageJobDetailComponent },
      {path: 'index', component: IndexComponent },
      {path: '#', component: SwitcherComponent },
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
