export class JobModel {

    id:any;
    title:String;
    shortDescription:String;
    description:any;
    responsibilities:any
    employeeType:String;
    location:String;
    jobType:String;
    experience:String;
    skillsAndQualifications:String;
    icon:String;
}