<section class="section" id="software-solutions">
    <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">{{'softwareSolutions.title' | translate}}</h4>
            <!-- <p class="text-muted para-desc mx-auto mb-0">Start working with <span class="text-primary font-weight-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p> -->
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-12">
                <div class="features mt-5">
                    <div class="image position-relative d-inline-block">
                        <img src="assets/images/icon/smartphone.svg" class="avatar avatar-small" alt="">
                    </div>

                    <div class="content mt-4">
                        <h4 class="title-2">{{'softwareSolutions.mobileApp.title' | translate}}</h4>
                        <p class="text-muted mb-0">{{'softwareSolutions.mobileApp.description' | translate}}.</p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-4 col-12 mt-5">
                <div class="features">
                    <div class="image position-relative d-inline-block">
                        <img src="assets/images/icon/video.svg" class="avatar avatar-small" alt="">
                    </div>

                    <div class="content mt-4">
                        <h4 class="title-2">{{'softwareSolutions.softwareDew.title' | translate}}</h4>
                        <p class="text-muted mb-0">{{'softwareSolutions.softwareDew.description' | translate}}.</p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-4 col-12 mt-5">
                <div class="features">
                    <div class="image position-relative d-inline-block">
                        <img src="assets/images/icon/intellectual.svg" class="avatar avatar-small" alt="">
                    </div>

                    <div class="content mt-4">
                        <h4 class="title-2">{{'softwareSolutions.UIDesign.title' | translate}}</h4>
                        <p class="text-muted mb-0">{{'softwareSolutions.mobileApp.description' | translate}}</p>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>