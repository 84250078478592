<!-- Offer Section Start -->
<section class="section bg-light">
    <div class="col-12 text-center">
        <div class="section-title">
            <h4 class="title text-primary">{{ 'offer.title' | translate }}</h4>
            <p class="text-muted">{{ 'offer.description' | translate }}</p>
        </div>
    </div>
    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <!-- Contact Details -->
            <div class="col-lg-6 col-md-6 order-2 order-md-1">
                <div class="title-heading ml-lg-4">
                    <h4 class="mb-4">{{ 'offer.contact_details' | translate }}</h4>
                    <p class="text-muted">{{ 'offer.contact_description' | translate }}</p>

                    <!-- Email Contact -->
                    <div class="media contact-detail align-items-center mt-3">
                        <div class="icon">
                            <i-feather name="mail" class="fea icon-m-md text-dark mr-3"></i-feather>
                        </div>
                        <div class="media-body content">
                            <h4 class="title font-weight-bold mb-0">{{ 'offer.email' | translate }}</h4>
                            <a href="mailto:info@artemitsoft.com" class="text-primary">artemitsoft.info@gmail.com</a>
                        </div>
                    </div>

                    <!-- Phone Contact -->
                    <div class="media contact-detail align-items-center mt-3">
                        <div class="icon">
                            <i-feather name="phone" class="fea icon-m-md text-dark mr-3"></i-feather>
                        </div>
                        <div class="media-body content">
                            <h4 class="title font-weight-bold mb-0">{{ 'offer.phone' | translate }}</h4>
                            <a href="tel:+90433224508" class="text-primary">+90 (543) 322 4508</a>
                        </div>
                    </div>

                    <!-- Location Contact -->
                    <div class="media contact-detail align-items-center mt-3">
                        <div class="icon">
                            <i-feather name="map-pin" class="fea icon-m-md text-dark mr-3"></i-feather>
                        </div>
                        <div class="media-body content">
                            <h4 class="title font-weight-bold mb-0">{{ 'offer.location' | translate }}</h4>
                            <a href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3021.1630459057596!2d29.50110557606469!3d40.780429533423536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cb219fb27ddacf%3A0x5e5dbea1b92cca7f!2sBili%C5%9Fim%20Vadisi!5e0!3m2!1str!2str!4v1724067483674!5m2!1str!2str"
                                class="video-play-icon text-primary">
                                Bilişim Vadisi, Muallimköy Mh. Deniz Cd. <br>Gebze, Kocaeli
                                
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Offer Form -->
            <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-1 order-md-2">
                <div class="card custom-form rounded shadow border-0">
                    <div class="card-body">
                        <div id="message"></div>
                        <form method="post" action="php/contact.php" name="contact-form" id="contact-form">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group position-relative">
                                        <input name="name" id="name" type="text" class="form-control pl-2" placeholder="{{ 'offer.form.full_name' | translate }}">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group position-relative">
                                        <input name="email" id="email" type="email" class="form-control pl-2" placeholder="{{ 'offer.form.email' | translate }}">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group position-relative">
                                        <input name="phone" id="phone" type="text" class="form-control pl-2" placeholder="{{ 'offer.form.phone' | translate }}">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group position-relative">
                                        <input name="company" id="company" type="text" class="form-control pl-2" placeholder="{{ 'offer.form.company' | translate }}">
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group position-relative">
                                        <textarea name="comments" id="comments" rows="4" class="form-control pl-2" placeholder="{{ 'offer.form.message' | translate }}"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 text-center">
                                    <input type="submit" id="submit" name="send" class="submitBnt btn btn-primary btn-block" value="{{ 'offer.form.submit' | translate }}">
                                    <div id="simple-msg"></div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- End Offer Form -->
        </div>
    </div>
</section>
<!-- Offer Section End -->

<!-- Google Map -->
<div class="container-fluid">
    <div class="row">
        <div class="col-12 p-0">
            <div class="card map border-0">
                <div class="card-body p-0">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3021.1630459057596!2d29.50110557606469!3d40.780429533423536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cb219fb27ddacf%3A0x5e5dbea1b92cca7f!2sBili%C5%9Fim%20Vadisi!5e0!3m2!1str!2str!4v1724067483674!5m2!1str!2str"
                        style="border:0" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Google Map End -->