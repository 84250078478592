import { Component, OnInit } from '@angular/core';
import { JobService } from 'src/app/providers/job.service';
import { JobModel } from 'src/app/models/job.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-jobs-banner',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.css']
})
export class JobsComponent implements OnInit {

  jobs:JobModel[]=[];

  constructor(private jobService:JobService,private router: Router,) { }



  ngOnInit(): void {
    this.jobService.getJobs().subscribe((resp)=>{
      this.jobs=resp;
      console.log(this.jobs);
    })
  }

  Detail(diagnose:any){
    this.router.navigate(['/page-job-detail',diagnose]);
  }


}
