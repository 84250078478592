<!-- Hero Start -->
<section class="bg-half-170 d-table w-100 overflow-hidden" id="home">
    <div class="container">

        <h3 class="title"><b>Privacy Policy - Update Software
</b></h3>
        <p> Your privacy is important to us. This privacy policy informs you about why we collect data from our users. This privacy policy is part of our terms of service. It applies to application installation, application usage or any information collected
            on our site. Please contact us at <a href="info@artemitsoft.com">info@artemitsoft.com</a> with any questions or regarding our policies.</p>

        <h4 class="title"><b>Data Collection
</b></h4>
        <p>This page is used to inform users of our policies regarding the collection, use and disclosure of Personal Information should anyone decide to use our Service.</p>

        <p>If you choose to use our service, you consent to the collection and use of information related to this policy. We do not collect any information about our users and currently used devices. However, if we are to collect information in the future,
            it will only be used to provide and improve our services. We will not use or share your information with anyone except as described in this Privacy Policy.</p>

        <br />
        <h4 class="title"><b>Data Usage (Update Software App)</b></h4>
        <p>The collected data is exclusively used for the purpose of checking the current status of our application and for retrieving the latest updates of applications installed on your device, based on the package names of publicly available applications.</p>
        <p>App Updates: Requests made to the IP address are utilized to verify the current status of our application and to retrieve the most recent updates of applications installed on your device. These requests are made to inform our users whether their
            applications are up-to-date and if any updates are available. Please be assured that the collected data is used solely for these specific purposes, and no personal or sensitive information is collected or transmitted.</p>

        <br />
        <h4 class="title"><b>User Data Policy for the Software Update Feature</b></h4>
        <p>To identify apps with pending updates, the software update feature takes the package names of all apps on the user's device and places them on our servers. This data is fully encrypted and secure. We do not share this data in any way with any
            third party. Due to changing Google Policies, we will ask our users to accept this within the app. They will be able to use the software update feature only after they accept it.</p>

        <br />
        <h4 class="title"><b>The information Which we Collect</b></h4>
        <p> We only collect some harmless personal data to serve you better.</p>

        <br />
        <h4 class="title"><b>Private Information </b></h4>
        <p> We do not collect any personal information such as name, physical address, email addresses, phone numbers or SSN stored on your device with our apps.</p>

        <br />
        <h4 class="title"><b>How to contact us </b></h4>
        <p> For questions or comments regarding our privacy policy or privacy practices, please contact us at info@artemitsoft.com.</p>

        <br />
        <h4 class="title"><b>CHANGES TO OUR PRIVACY POLICY </b></h4>
        <p> Please review this policy periodically as we will post any changes to our privacy policy here. We will try to notify you by other means after any changes or updates to our privacy policy. If you do not agree to any changes to our policies, you
            can immediately stop all services.</p>

        <br />
        <h4 class="title"><b>Limited Liability </b></h4>
        <p> Limited Liability By using our applications, you agree that ArtemitSoft is not responsible for any negative situations that may arise from the use of our applications.</p>

        <br />
        <h4 class="title"><b>Support for free version </b> </h4>
        <p> We always try to fix all app issues in time. However, some of our apps may be dealing with hardware issues over which we have no control and which we cannot fix. If you switch devices, data and settings from your old phone app will not be automatically
            migrated to your new device. You will have to reconfigure them manually.</p>

        <br />
        <h4 class="title"><b>THIRD-PARTY SUPPLIERS </b></h4>
        <p> We never share your data with third parties with our applications.</p>

        <br />

        <h3 class="title"><b>Accessibility services </b></h3>
        <p> Some of our apps require accessibility permissions to serve their purpose.</p>

        <br />
        <h4 class="title"><b>ACCESS_WIFI_STATE,ACCESS_NETWORK_STATE,INTERNET </b></h4>
        <p> Internet requirement is provided in order to provide version control of the application.</p>


        <br />
        <h4 class="title"><b>RECEIVE_BOOT_COMPLETED </b></h4>
        <p> Allows an application to receive the Intent.ACTION_BOOT_COMPLETED that is broadcast after the system finishes booting. If you don't request this permission, you will not receive the broadcast at that time. </p>


        <br />
        <h4 class="title"><b>VIBRATE </b></h4>
        <p> Allows using PowerManager WakeLocks to keep processor from sleeping or screen from dimming.</p>



        <br />
        <h4 class="title"><b>WAKE_LOCK </b></h4>
        <p>Allows using PowerManager WakeLocks to keep processor from sleeping or screen from dimming. </p>



        <br />
        <h4 class="title"><b>UPDATE_COUNT </b></h4>
        <p>Internet requirement is provided in order to provide version control of the application. </p>

        <br />
        <h4 class="title"><b>BADGE_COUNT_READ </b></h4>
        <p> Notification count show on map above app logo</p>

        <p> If you choose to use our service, you consent to the collection and use of information related to this policy. Our users and currently used devices are entitled to</p>

    </div>
</section>