<div class="container" id="getin-touch">
    <div class="row my-md-5 pt-md-3 my-4 pt-2 pb-lg-5 mt-sm-0 pt-sm-0 justify-content-center">
        <div class="col-12 text-center">
            <div class="section-title">
                <h4 class="title mb-4">{{ 'getInTouch.title' | translate }}</h4>
                <p class="text-muted para-desc mx-auto" [innerHTML]=" 'getInTouch.description' | translate "></p>
                <a href="/page-contact" class="btn btn-primary mt-4"><i class="mdi mdi-email"></i>  {{ 'home.button' | translate }}</a>
            </div>
        </div>
        <!--end col-->
    </div>
    <!--end row-->
</div>
<!--end container-->