import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JobService } from 'src/app/providers/job.service';
import { JobModel } from 'src/app/models/job.model';

@Component({
  selector: 'app-page-job-detail',
  templateUrl: './page-job-detail.component.html',
  styleUrls: ['./page-job-detail.component.css']
})
export class PageJobDetailComponent implements OnInit {

  param:any="";
  selectedJob:JobModel;
  constructor(private route: ActivatedRoute,private jobService:JobService) {
   this.selectedJob = new JobModel
   }

  ngOnInit(): void {
    this.param = this.route.snapshot.paramMap.get('id');
    console.log(this.param);

    this.jobService.getJobs().subscribe((jobs)=>{
      this.selectedJob = jobs.find(item=>item.id==this.param);
    })
  }

}
