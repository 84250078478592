import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home-banner',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  isLoad:boolean =false;
  titles: string[] = [];
  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    this.translate.get('home.title').subscribe((titles: string[]) => {
      this.titles = titles || [];
      console.log( this.titles)
      this.isLoad=true;
    });
  }

}
