<section id="features">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">{{ 'features.title' | translate }}</h4>
                    <p class="text-muted para-desc mx-auto mb-0" [innerHTML]=" 'features.description' | translate "></p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
            <div class="col-md-4 col-12 mt-5">
                <div class="features" data-aos="fade-up" data-aos-duration="1000">
                    <div class="image position-relative d-inline-block">
                        <img src="assets/images/icon/pen.svg" class="avatar avatar-small" alt="">
                    </div>
                    <div class="content mt-4">
                        <h4 class="title-2">{{ 'features.items.designDevelopment.title' | translate }}</h4>
                        <p class="text-muted mb-0">{{ 'features.items.designDevelopment.description' | translate }}</p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-4 col-12 mt-5">
                <div class="features" data-aos="fade-up" data-aos-duration="1400">
                    <div class="image position-relative d-inline-block">
                        <img src="assets/images/icon/video.svg" class="avatar avatar-small" alt="">
                    </div>
                    <div class="content mt-4">
                        <h4 class="title-2">{{ 'features.items.managementMarketing.title' | translate }}</h4>
                        <p class="text-muted mb-0">{{ 'features.items.managementMarketing.description' | translate }}</p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-4 col-12 mt-5">
                <div class="features" data-aos="fade-up" data-aos-duration="1800">
                    <div class="image position-relative d-inline-block">
                        <img src="assets/images/icon/intellectual.svg" class="avatar avatar-small" alt="">
                    </div>
                    <div class="content mt-4">
                        <h4 class="title-2">{{ 'features.items.strategyResearch.title' | translate }}</h4>
                        <p class="text-muted mb-0">{{ 'features.items.strategyResearch.description' | translate }}</p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-4 col-12 mt-5">
                <div class="features" data-aos="fade-up" data-aos-duration="2200">
                    <div class="image position-relative d-inline-block">
                        <img src="assets/images/icon/user.svg" class="avatar avatar-small" alt="">
                    </div>
                    <div class="content mt-4">
                        <h4 class="title-2">{{ 'features.items.easyToUse.title' | translate }}</h4>
                        <p class="text-muted mb-0">{{ 'features.items.easyToUse.description' | translate }}</p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-4 col-12 mt-5">
                <div class="features" data-aos="fade-up" data-aos-duration="2400">
                    <div class="image position-relative d-inline-block">
                        <img src="assets/images/icon/calendar.svg" class="avatar avatar-small" alt="">
                    </div>
                    <div class="content mt-4">
                        <h4 class="title-2">{{ 'features.items.dailyReports.title' | translate }}</h4>
                        <p class="text-muted mb-0">{{ 'features.items.dailyReports.description' | translate }}</p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-4 col-12 mt-5">
                <div class="features" data-aos="fade-up" data-aos-duration="2800">
                    <div class="image position-relative d-inline-block">
                        <img src="assets/images/icon/sand-clock.svg" class="avatar avatar-small" alt="">
                    </div>
                    <div class="content mt-4">
                        <h4 class="title-2">{{ 'features.items.onTimeDelivery.title' | translate }}</h4>
                        <p class="text-muted mb-0">{{ 'features.items.onTimeDelivery.description' | translate }}</p>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
</section>