<section class="section" id="technologies">
    <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">{{ 'technologies.title' | translate }}</h4>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-4 mt-4 pt-2" *ngFor="let item of images">
                <div class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                    <div class="card-body p-0 content">
                        <img src="assets/images/techonology/{{item.item}}.png" style="max-height: 35px !important;" class="avatar avatar-ex-sm" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
