<div class="container">
    <div class="row align-items-center" id="counter">
        <div class="col-md-6">
            <img src="assets/images/company/about2.png" class="img-fluid" alt="">

        </div>
        <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div class="ml-lg-4">
                <div class="d-flex mb-4">
                    <span class="text-primary h1 mb-0"><span class="counter-value display-1 font-weight-bold"
            [CountTo]="yearsCount"
            [from]="0" [duration]="1"></span>+</span>
                    <span class="h6 align-self-end ml-2">{{ 'whoWeAre.counter' | translate }}</span>
                </div>
                <div class="section-title">
                    <h4 class="title mb-4">{{ 'whoWeAre.title' | translate }}</h4>
                    <p class="text-muted" [innerHTML]=" 'whoWeAre.description1' | translate"></p>
                    <p class="text-muted" [innerHTML]=" 'whoWeAre.description2' | translate"></p>
                    <p class="text-muted" [innerHTML]=" 'whoWeAre.description3' | translate"></p>
                    <p class="text-muted" [innerHTML]=" 'whoWeAre.description4' | translate"></p>
                    <p class="text-muted" [innerHTML]=" 'whoWeAre.description5' | translate"></p>

                    <a href="/page-contact" class="btn btn-primary mt-3">{{ 'whoWeAre.button' | translate }}</a>
                </div>
            </div>
        </div>
    </div>
</div>