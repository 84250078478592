<!-- Footer Start -->
<footer class="footer bg-light">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                <a href="#" class="logo-footer">
                    <img src="assets/artemitlogo.svg" height="40" alt="artemitSoft | mobile application">
                </a>
                <p class="mt-4 text-muted">{{ 'footer.about' | translate }}</p>
                <ul class="list-unstyled social-icon social mb-0 mt-4">
                    <li class="list-inline-item">
                        <a href="https://www.facebook.com/profile.php?id=100071075726096" target="_blank" class="rounded">
                            <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                        </a>
                    </li>
                    <li class="list-inline-item ml-1">
                        <a href="https://www.instagram.com/artemitsoft" target="_blank" class="rounded">
                            <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                        </a>
                    </li>
                    <li class="list-inline-item ml-1">
                        <a href="https://x.com/ArtemitSoft" class="rounded" target="_blank">
                            <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                        </a>
                    </li>
                    <li class="list-inline-item ml-1">
                        <a href="javascript:void(0)" class="rounded">
                            <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                        </a>
                    </li>
                </ul>
                <!--end icon-->
            </div>

            <div class="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <h4 class="text-dark footer-head">{{ 'footer.solutions' | translate }}</h4>
                <ul class="list-unstyled footer-list mt-4">
                    <li><a routerLink="/page-terms" class="text-muted"><i class="mdi mr-1"></i> {{ 'solutions.item1' | translate }}</a></li>
                    <li><a routerLink="/page-terms" class="text-muted"><i class="mdi mr-1"></i> {{ 'solutions.item2' | translate }}</a></li>
                    <li><a routerLink="/page-terms" class="text-muted"><i class="mdi mr-1"></i> {{ 'solutions.item3' | translate }}</a></li>
                    <li><a routerLink="/page-terms" class="text-muted"><i class="mdi mr-1"></i> {{ 'solutions.item4' | translate }}</a></li>
                    <li><a routerLink="/page-terms" class="text-muted"><i class="mdi mr-1"></i> {{ 'solutions.item5' | translate }}</a></li>
                    <li><a routerLink="/page-terms" class="text-muted"><i class="mdi mr-1"></i> {{ 'solutions.item6' | translate }}</a></li>
                </ul>
            </div>

            <div class="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <h4 class="text-dark footer-head">{{ 'footer.contactInfo' | translate }}</h4>
                <ul class="list-unstyled footer-list mt-4">
                    <li>
                        <i-feather name="map-pin" class="fea icon-sm text-foot mr-3"></i-feather><a class="text-muted">{{ 'footer.address' | translate }}</a>
                    </li>
                    <li>
                        <i-feather name="mail" class="fea icon-sm text-foot mr-3"></i-feather><a href="tel:+05433224508" class="text-muted">{{ 'footer.phone' | translate }}</a>
                    </li>
                    <li>
                        <i-feather name="phone" class="fea icon-sm text-foot mr-3"></i-feather><a href="mailto:artemitsoft.info@gmail.com" class="text-muted">{{ 'footer.email' | translate }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</footer>
<!--end footer-->
<footer class="footer footer-bar bg-primary">
    <div class="container text-center">
        <div class="row align-items-center">
            <div class="col-sm-12">
                <div class="text-sm-left">
                    <p class="mb-0">{{ 'footer.rights' | translate }}</p>
                </div>
            </div>
        </div>
    </div>
</footer>