<div class="container">
    <div class="row">
        <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card job-box rounded shadow border-0 overflow-hidden">
                <div class="border-bottom">
                    <div class="position-relative">
                        <img src="assets/images/job/full.jpg" class="img-fluid" alt="">
                        <div class="job-overlay bg-white"></div>
                    </div>
                    <h5 class="mb-0 position"><a routerLink="/page-job-detail" class="text-dark">Web
                    Designer</a>
                        <ul class="list-unstyled h6 mb-0 text-warning">
                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>
                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>
                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>
                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>
                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>
                        </ul>
                    </h5>
                    <ul class="list-unstyled head mb-0">
                        <li class="badge badge-success badge-pill">Full Time</li>
                    </ul>
                </div>

                <div class="card-body content position-relative">
                    <div class="firm-logo rounded-circle shadow bg-light text-center">
                        <img src="assets/images/job/Circleci.svg" class="avatar avatar-md-sm" alt="">
                    </div>
                    <div class="company-detail text-center mt-3">
                        <h5 class="mb-0"><a routerLink="/page-job-company" class="text-dark company-name">CircleCi</a></h5>
                        <p class="text-muted">
                            <a href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                                class="video-play-icon text-muted">
                                <i-feather name="map-pin" class="fea icon-sm"></i-feather>
                                San Francisco
                            </a>
                        </p>
                    </div>
                    <ul class="job-facts list-unstyled">
                        <li class="list-inline-item text-muted">
                            <i-feather name="check" class="fea icon-sm text-success mr-1"></i-feather>
                            2 Year Expirence
                        </li>
                        <li class="list-inline-item text-muted">
                            <i-feather name="check" class="fea icon-sm text-success mr-1"></i-feather>
                            Working Hours- 6hr
                        </li>
                        <li class="list-inline-item text-muted">
                            <i-feather name="check" class="fea icon-sm text-success mr-1"></i-feather>
                            Information strategy
                        </li>
                    </ul>
                    <a routerLink="/page-job-detail" class="btn btn-outline-primary btn-block">Apply Now</a>
                </div>
            </div>
        </div>
        <!--end col-->

        <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card job-box rounded shadow border-0 overflow-hidden">
                <div class="border-bottom">
                    <div class="position-relative">
                        <img src="assets/images/job/full.jpg" class="img-fluid" alt="">
                        <div class="job-overlay bg-white"></div>
                    </div>
                    <h5 class="mb-0 position"><a routerLink="/page-job-detail" class="text-dark">Web Developer</a>
                        <ul class="list-unstyled h6 mb-0 text-warning">
                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>
                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>
                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>
                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>
                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>
                        </ul>
                    </h5>
                    <ul class="list-unstyled head mb-0">
                        <li class="badge badge-success badge-pill">Full Time</li>
                    </ul>
                </div>

                <div class="card-body content position-relative">
                    <div class="firm-logo rounded-circle shadow bg-light text-center">
                        <img src="assets/images/job/Codepen.svg" class="avatar avatar-md-sm" alt="">
                    </div>
                    <div class="company-detail text-center mt-3">
                        <h5 class="mb-0"><a routerLink="/page-job-company" class="text-dark company-name">Codepen</a></h5>
                        <p class="text-muted">
                            <a href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                                class="video-play-icon text-muted">
                                <i-feather name="map-pin" class="fea icon-sm"></i-feather>
                                San Francisco
                            </a>
                        </p>
                    </div>
                    <ul class="job-facts list-unstyled">
                        <li class="list-inline-item text-muted">
                            <i-feather name="check" class="fea icon-sm text-success mr-1"></i-feather>
                            2 Year Expirence
                        </li>
                        <li class="list-inline-item text-muted">
                            <i-feather name="check" class="fea icon-sm text-success mr-1"></i-feather>
                            Working Hours- 6hr
                        </li>
                        <li class="list-inline-item text-muted">
                            <i-feather name="check" class="fea icon-sm text-success mr-1"></i-feather>
                            Information strategy
                        </li>
                    </ul>
                    <a routerLink="/page-job-detail" class="btn btn-outline-primary btn-block">Apply Now</a>
                </div>
            </div>
        </div>
        <!--end col-->

        <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card job-box rounded shadow border-0 overflow-hidden">
                <div class="border-bottom">
                    <div class="position-relative">
                        <img src="assets/images/job/part.jpg" class="img-fluid" alt="">
                        <div class="job-overlay bg-white"></div>
                    </div>
                    <h5 class="mb-0 position"><a routerLink="/page-job-detail" class="text-dark">UX Designer</a>
                        <ul class="list-unstyled h6 mb-0 text-warning">
                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>
                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>
                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>
                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>
                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>
                        </ul>
                    </h5>
                    <ul class="list-unstyled head mb-0">
                        <li class="badge badge-warning badge-pill">Part Time</li>
                    </ul>
                </div>

                <div class="card-body content position-relative">
                    <div class="firm-logo rounded-circle shadow bg-light text-center">
                        <img src="assets/images/job/Discord.svg" class="avatar avatar-md-sm" alt="">
                    </div>
                    <div class="company-detail text-center mt-3">
                        <h5 class="mb-0"><a routerLink="/page-job-company" class="text-dark company-name">Discord</a></h5>
                        <p class="text-muted">
                            <a href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                                class="video-play-icon text-muted">
                                <i-feather name="map-pin" class="fea icon-sm"></i-feather>
                                San Francisco
                            </a>
                        </p>
                    </div>
                    <ul class="job-facts list-unstyled">
                        <li class="list-inline-item text-muted">
                            <i-feather name="check" class="fea icon-sm text-success mr-1"></i-feather>
                            2 Year Expirence
                        </li>
                        <li class="list-inline-item text-muted">
                            <i-feather name="check" class="fea icon-sm text-success mr-1"></i-feather>
                            Working Hours- 6hr
                        </li>
                        <li class="list-inline-item text-muted">
                            <i-feather name="check" class="fea icon-sm text-success mr-1"></i-feather>
                            Information strategy
                        </li>
                    </ul>
                    <a routerLink="/page-job-detail" class="btn btn-outline-primary btn-block">Apply Now</a>
                </div>
            </div>
        </div>
    </div>
</div>